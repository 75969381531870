/*******************************
Input Overrides - REACT-INVENIO-APP-ILS
*******************************/


.ui.input.action.ils-searchbar {
  button.ui.icon{
    background-color: @fsPrimaryColor;
    color: white;
  }
}
