/*******************************
Sticky Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.stuck-container {
  &.solid-background {
    .ui.sticky{
      background-color: white;
    }
  }
}
