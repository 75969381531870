/*******************************
Grid Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.grid {
  &.search-controls {
    padding-top: 1em;
    padding-bottom: 2em;
  }
  &.search-footer-pagination{
    padding: @defaultPaddingValue;
  }
  .column {
    &.scrolling{
      overflow: auto;
      max-height: 60vh;
    }
  }
  .layout-switcher{
    .ui.menu{
      box-shadow: none;
    }
  }
}

