/*******************************
Search Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.fluid.search{
  width: 100%;
  .ui.icon.input{
    width: 100%;
  }
}

.ui.search{
  .select-disabled{
    cursor: not-allowed;
    .price, .title, .description{
      color: @mutedTextColor !important;
    }
  }
}
