/*******************************
Accordion Overrides - REACT-INVENIO-APP-ILS
*******************************/


.frontsite{
  .ui.accordion{
    &.document-items-location-menu{
      &.menu{
        .item{
          .title{
            padding: 0.5em;
            border: 1px solid @fsBorderColor;
            .ui.label{
              margin-right: 1em;
            }
          }
          .content{
            border: 1px solid @fsBorderColor;
            border-top: 0;
          }
        }
      }
    }
  }
}

.backoffice{
  .ui.accordion{
    &.styled{
      box-shadow: none;
      >.content {
          margin-left: 0;
          margin-right: 0;

          padding: 0;
          border: 1px solid @borderColor;
          border-top: 0;
          border-radius: 0 0 @detailsBorderRadius @detailsBorderRadius;
        &.active{
          margin-bottom: 1em;
        }
        }

    }
    &.highlighted{
      .title {
        background-color: #e8e8e8;
        border: 1px solid #ddd;
        color: rgba(0, 0, 0, 0.6);
        border-radius: @detailsBorderRadius;
        font-size: 1.1em;
        padding: 0.5em 0.3em;
        margin-bottom: 1em;
        &.active {
          margin-bottom: 0;
          border-radius: @detailsBorderRadius @detailsBorderRadius 0 0;
        }
      }
    }
    .document-series{
      margin-top: 1em;
    }
  }
}
