/*******************************
Button Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.button {
  &.attached.left {
    &.bo-remove-item {
      display: inline-table;
      z-index: 1;
      position: absolute;
      left: -1em;
    }
  }

  &.copy {
    box-shadow: none;
    padding: 0.1rem;
  }
  
  &.formik-button-margin {
    margin-bottom: 3em !important;
  }
}


.ui.button {
    &.button.send-overdue-reminder-button > .button{
      padding-left: 2.5em !important;
    }
    .label.button-with-additional-info {
      padding: 10px !important;
    }
    .icon.button-with-additional-info {
      margin-left: 0.5em !important;
    }
    .label.send-overdue-reminder-button {
      color: @orange;
      border-color: @orange;
      padding: 10px;
    }
    .icon.send-overdue-reminder-button {
      margin-left: 0.5em !important;
    }
  }

@{fs-parent-selector} {
  .button-show-more {
    color: @fsPrimaryColor;
    font-weight: bold;
    cursor: pointer;
  }

  .ui.button {
    &.primary {
      background-color: @fsPrimaryColor;
    }

    &.basic.fs-button-sort-mobile {
      box-shadow: none;
      border: 0;
    }

    &.headline-quick-access {
      margin: 0.5em;
    }
  }
}

@{bo-parent-selector} {
  .button-show-more {
    color: @primaryColor;
    font-weight: bold;
    cursor: pointer;
  }

  .bo-action-menu {
    .ui.button {
      margin-bottom: 0.5em;
    }
  }
}
