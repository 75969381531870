/*******************************
Modal Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.modal{
  > .close{
    top: 0.5em;
    right: 0.2em;
    color: black;
  }
}
