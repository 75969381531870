/*******************************
Loader Overrides - REACT-INVENIO-APP-ILS
*******************************/


@{fs-parent-selector} {
  .ui.loader {
    &.full-height {
      min-height: 60vh;
    &:before{
      top: 40%
    }
      &:after{
        top:40%
      }
    }
  }
}
