/*******************************
Form Overrides - REACT-INVENIO-APP-ILS
*******************************/

.field .ui.dropdown {
  .ui.label.error {
    background-color: @dropdownErrorColor;
  }
}

.fields {
  margin: 0 !important;
}

.form-group {
  border: none;
  margin-bottom: 1rem !important;
  padding-top: 0;
  position: relative;

  >.field {
    padding-left: 0 !important;
    margin-bottom: 1em !important;
  }

  div.group-action {
    position: absolute;
    right: -0.4em;
    top: -0.3em;

    .button {
      box-shadow: none;
      margin: 0;
      padding: 0.4em 0.5em;
    }
  }

  &.border {
    border: @fieldsetBorder;
    border-radius: @fieldsetBorderRadius;
    padding: 0.6rem 0.3rem 0 0.3rem;

    .field {
      padding-left: 0.3em !important;
    }
  }

  &.error {
    border-color: @fieldsetErrorBorderColor;

    legend {
      color: @fieldsetErrorTextColor;
    }
  }
}

.object-list-field {
  div.ui.list {
    a.item.active {
      color: @orange;

      .list.icon {
        color: @black;
      }
    }
  }
}
