/*******************************
Divider Overrides - REACT-INVENIO-APP-ILS
*******************************/

.document-metadata {
  .ui.horizontal.divider:before {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 0;
    background-repeat: no-repeat;
  }

  .ui.horizontal.divider:after {
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 100%;
    background-repeat: no-repeat;
  }
}

.grey-color {
  color: rgba(0, 0, 0, 0.4) !important;
}

.ui.horizontal.divider.document-circulation {
  margin: 1em 0 1em 0;
}
