/*******************************
Site Overrides - REACT-INVENIO-APP-ILS
*******************************/

/*-------------------------
       Fonts extension
https://github.com/Semantic-Org/Semantic-UI/issues/5642
-------------------------*/
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Regular.ttf') format('truetype');
  unicode-range: U+04??, U+0500-052F, U+2DE0-2DFF, U+A640-A69F, U+1D2B-1D78;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Italic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Italic.ttf') format('truetype');
  unicode-range: U+04??, U+0500-052F, U+2DE0-2DFF, U+A640-A69F, U+1D2B-1D78;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-Bold.ttf') format('truetype');
  unicode-range: U+04??, U+0500-052F, U+2DE0-2DFF, U+A640-A69F, U+1D2B-1D78;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.ttf') format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* cyrillic */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../../fonts/Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lato-BoldItalic.woff2') format('woff2'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lato-BoldItalic.ttf') format('truetype');
  unicode-range: U+04??, U+0500-052F, U+2DE0-2DFF, U+A640-A69F, U+1D2B-1D78;
}

/*-------------------------
           GLOBAL
-------------------------*/
#app {
  height: 100%;
}

.center {
  text-align: center;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.default-padding {
  padding: 1rem;
}

.pt-default {
  padding-top: 1em;
}

.pb-default {
  padding-bottom: 1em;
}

.no-border {
  border: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.inline-block {
  display: inline-block;
}

.default-margin-top {
  margin-top: @defaultMarginValue !important;
}

.default-margin-bottom {
  margin-bottom: @defaultMarginValue !important;
}

.default-margin {
  margin: @defaultMarginValue !important;
}

.background-highlight {
  background-color: @highlightBackgroundColor;
}

.danger {
  color: @danger;
}

.success {
  color: @success;
}

.warning-color {
  color: @warning;
}

.bkg-warning {
  background-color: @warning !important;
  color: white !important;
}

.bkg-danger {
  background-color: @danger !important;
}

.bkg-success {
  background-color: @success !important;
}

label {
  font-weight: bold;
  &.muted {
    border: 0;
    padding: 2px;
    color: @mutedTextColor;
    background-color: transparent;
  }
}

.search-aggregations {
  label {
    font-weight: normal;
  }
}

form {
  label {
    font-weight: normal;
  }
}

.spaced {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.uppercase {
  text-transform: uppercase;
}

a {
  &.alternative {
    color: @altLinkColor;
  }
  &.disabled {
    cursor: default;
    color: @disabledTextColor;
  }
}

.menu-item-description {
  font-size: 0.8em;
}

.custom-year-margin {
  margin: 0em !important;
}

.vertical-align-content{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.ui.label.environment-label {
  &.frontsite-computer {
    margin-left: 1.6em;
  }
  &.frontsite-mobile {
    position: absolute;
    margin-left: 1.6em;
    margin-top: -1em;
    padding-left: 0.8em;
    padding-right: 0;
  }
  &.backoffice {
    margin-bottom: 0;
  }
}

.ui.pagination [type=ellipsisItem]:nth-last-child(3) + [type=pageItem] {
  /* Workaround to hide the last page item button */
  display: none !important;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

/* FRONTSITE */

@media only screen and (max-width: @largestMobileScreen) {
  .frontsite{
    .auth-page {
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
}

.frontsite {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bkg-primary {
    background-color: @fsPrimaryColor;
  }

  a.primary {
    color: @fsPrimaryColor;
  }

  a.dark {
    color: black;
    text-decoration: underline;
  }

  a.dark:hover{
    color: #1aa3ff;
  }

  a.relations-title{
    font-style: italic;
  }

  #notifications {
    z-index: 9999;
    margin-top: 1rem;

    &.compact {
      position: fixed;
      right: 1em;
      top: @headerMenuHeight+1em;
      z-index: 9999;
      margin: 0;
    }

    .message {
      padding-right: 2.5em;
    }
  }

  .breadcrumbs {
    padding-bottom: 2em;
  }

  span.secondary {
    color: @fsSecondaryColor;
  }

  .mobile-results-page {
    .ui.compact.dropdown {
      font-size: 15px;
    }
  }

  .mobile-count {
    line-height: 2em;
  }

  .frontsite-list {
    list-style-type: none;
    padding-left: 0em;
  }

  .document-items-tab-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .list {
    .alternative-title {
      padding-top: 0 !important;
    }
  }


  .document-item {

    &-internal-title {
      margin-left: 0.5rem;
      margin-bottom: 0.1rem;
    }
    &-table {
      margin-top: 0.1rem !important;
      margin-bottom: 1.5rem;

      &-itemCell {
        font-weight: normal !important;

      }
    }

    &-footer {

      &-text {
        margin:  0 0.5rem
      }
      &-innerWrapper {
        display: flex;
        justify-content: end;
        align-items: center;
      }

    }

  }

  .loan-request-delivery-date {
      margin-bottom: 0.5em;
  }

  .search-no-results-extra {
    font-weight: normal;
    font-size: medium;
  }
}

@media only screen and (max-width: @largestMobileScreen) {
  .frontsite{
    .document-items-tab-menu {
      align-items: center;
      justify-content: center;
    }
  }
}


/* BACKOFFICE */

.backoffice {
  display: flex;
  width: 100%;

  #notifications {
    position: fixed;
    right: 1em;
    top: 1em;
    z-index: 9999;

    .message {
      padding-right: 2.5em;
    }
  }

  .bo-sidebar {
    width: @boSidebarWidth;
    padding: 1.5em;
    background-color: @boSidebarBackgroundColor;
    position: relative;
    min-height: 100vh;
  }

  .bo-content {
    max-width: calc(~'100% - ' @boSidebarWidth) !important;
    flex: 1;
    padding: 1.5em;
  }

  .bo-details-header-status-labels {
    display: inline-block;
    margin-left: 1em;
  }
}

.display-inline-block {
  display: inline-block;
}

.generate-margins(@max, @direction, @alias, @i: 5) when (@i =< @max) {
  .m@{alias}-@{i} {
    margin-@{direction}: unit(@i, px) !important;
  }
  .generate-margins(@max, @direction, @alias, (@i + 5));
}

.generate-margins(30, right, r);
.generate-margins(30, left, l);
.generate-margins(30, bottom, b);
.generate-margins(30, top, t);

.generate-padding(@max, @direction, @alias, @i: 0) when (@i =< @max) {
  .p@{alias}-@{i} {
    padding-@{direction}: unit(@i, rem) !important;
  }
  .generate-padding(@max, @direction, @alias, (@i + 1))
}

.generate-padding(5, right, r);
.generate-padding(5, left, l);
.generate-padding(5, bottom, b);
.generate-padding(5, top, t);

/* React-JSONSchema-Forms */

// remove extra margin between field label and Dropdowns
.ui.form .grouped.fields .field {
  margin: 0 !important;
}

.float-right {
  float: right;
}
