
/*******************************
Statistic Overrides - REACT-INVENIO-APP-ILS
*******************************/


.ui.statistic.anchored {
  cursor: pointer;
}


@media only screen and (max-width: @largestMobileScreen) {
  @{fs-parent-selector} {
    .ui.statistics{
      &.three{
        .statistic{
          width: 100%;
          border-bottom: 2px solid @fsPrimaryColor;
          border-right: 0 !important;
        }
      }
    }
  }
}


@{fs-parent-selector} {
  .ui.statistic {
    padding: @fsStatisticPadding;
  }

  .ui.statistics{
    .ui.statistic{
      border-right: 2px solid @fsPrimaryColor;
      &:last-child{
        border-right: 0;
      }
    }
  }
}

