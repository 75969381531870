/*******************************
Dropdown Overrides - REACT-INVENIO-APP-ILS
*******************************/

.backoffice {
  .sort-by-filters {
    div {
      display: inline-block;
    }

    .ui.dropdown {
      margin-left: 0.5em;
      min-width: 10em;
    }
  }
}

.frontsite {
  .sort-by-filters {
    div {
      display: inline-block;
    }

    .ui.dropdown {
      margin-left: 0.5em;
    }
  }

  .mobile-filters-dropdown {
      margin-left: auto;
  }

  .ui.menu:not(.vertical) {
    .mobile-filters-dropdown {
      margin-right: 0 !important;
    }
  }

  .mobile-filters-dropdown.ui.dropdown.scrolling {
    .menu.transition.visible {
      max-height: 70vh;
    }
  }
}

.ui.menu.header-menu {
  .ui.dropdown {
    i {
      margin-left: 0.5em;
    }
  }
}

.ui.dropdown {
  position: relative;
}
