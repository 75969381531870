/*******************************
Image Overrides - REACT-INVENIO-APP-ILS
*******************************/

.literature-panel .ui.image.large {
  width: 350px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.3);
}

.ui.grid.grid-documents-search {
  .ui.image.image-cover,
  .image-cover > img {
    width: auto !important;
    height: 200px !important;
    background: none !important;
    box-shadow: 0 20px 10px -17px rgba(89, 89, 89);
  }
}

.ui {
  a > .image.image-cover,
  .image.image-cover {
    filter: drop-shadow(0px 1px 0px #eee) drop-shadow(1px 0px 0px #eee)
      drop-shadow(0px -1px 0px #eee) drop-shadow(-1px 0px 0px #eee);
  }
}