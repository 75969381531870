/*******************************
Label Overrides - REACT-INVENIO-APP-ILS
*******************************/

.ui.label.highlighted {
  border: 1px solid @fsPrimaryColor;
  background-color: white;
  color: @fsPrimaryColor;
  a {
    color: @fsPrimaryColor;
    opacity: 1;
    &:hover {
      color: @linkHoverColor;
    }
  }
}

.ui.label.muted {
  border: 0;
  padding: 0;
  color: @mutedTextColor;
  background-color: transparent;
}

.ui {
  .item-status-item-returned.label {
    background-color: #6435c9 !important;
    border-color: #6435c9 !important;
    color: #ffffff !important;
  }
  .item-status-item-at-desk.label {
    background-color: #2185d0 !important;
    border-color: #2185d0 !important;
    color: #ffffff !important;
  }
  .item-status-item-on-loan.label {
    background-color: #00b5ad !important;
    border-color: #00b5ad !important;
    color: #ffffff !important;
  }
  .item-status-item-in-transit-for-pickup.label {
    background-color: #21ba45 !important;
    border-color: #21ba45 !important;
    color: #ffffff !important;
  }
  .item-status-item-in-transit-to-house.label {
    background-color: #b5cc18 !important;
    border-color: #b5cc18 !important;
    color: #ffffff !important;
  }
  .item-status-cancelled.label {
    background-color: #fbbd08 !important;
    border-color: #fbbd08 !important;
    color: #ffffff !important;
  }
  .item-status-pending.label {
    background-color: #e03997 !important;
    border-color: #e03997 !important;
    color: #ffffff !important;
  }
  .item-status-on-shelf.label {
    background-color: #a333c8 !important;
    border-color: #a333c8 !important;
    color: #ffffff !important;
  }
  .item-status-for-reference-only.label {
    background-color: #a5673f !important;
    border-color: #a5673f !important;
    color: #ffffff !important;
  }
  .item-status-missing.label {
    background-color: #db2828 !important;
    border-color: #db2828 !important;
    color: #ffffff !important;
  }
  .item-status-in-binding.label {
    background-color: #767676 !important;
    border-color: #767676 !important;
    color: #ffffff !important;
  }
  .item-status-scanning.label {
    background-color: #f2711c !important;
    border-color: #f2711c !important;
    color: #ffffff !important;
  }
}
