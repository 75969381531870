/*******************************
Segment Overrides - REACT-INVENIO-APP-ILS
*******************************/

.bo-patron-details-tab {
  .ui.segment.active.tab {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


/* frontsite */
@{fs-parent-selector} {
  .ui.segment {
    box-shadow: none;
    border: 0;

    &.highlighted {
      background-color: @highlightBackgroundColor;
      padding: 1.6em;
      border-top: 2px solid #f2711c !important;
    }

    &.tab {
      background-color: inherit;
      padding: 0;
    }

    &.background-transparent {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.65);
    }
  }

  .literature-panel {
    .ui.segment {
      margin: 0 0 1rem 0;
    }
  }

  .section {
    .ui.segment {
      .table {
        background-color: inherit;
      }
    }
  }
}

@{bo-parent-selector} {
  .ui.segment[disabled] {
    pointer-events: none;
    opacity: 0.7;
  }
}


.ui.loading.segment.fs-segment-transparent {
  color: @textColor !important;
}

.ui.loading.segment.fs-segment-transparent:before {
  background: @loaderDimmerColor;
}

