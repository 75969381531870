/*******************************
Tab Overrides - REACT-INVENIO-APP-ILS
*******************************/

.frontsite{
  .patron-profile-tab{
    .item{
      i.icon{
        margin-left: 0.2em;
      }
    }
  }
}
