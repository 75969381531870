/*******************************
Icon Overrides - REACT-INVENIO-APP-ILS
*******************************/

i.icon {
  &.error {
    color: @errorTextColor;
  }

  &.bo-form-inline-icon {
    height: 1.2em;
  }
}

/* Backoffice */

.bo-relation-card {
  i.icon {
    margin-right: auto;
    margin-left: auto;
    margin-top: 0.2em;
  }
}

.info-popup {
  .icon {
    padding-left: 0.3rem;
  }
}
